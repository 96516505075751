@import '../../../assets/styles/variable';

.content_items_wrapper {
  display: flex;
  align-items: center;
  @media (max-width: 600px) {
    flex-wrap: wrap;
  }

  .left_side {
    font-size: 14px;
    line-height: 18px;
    width: 100%;
    margin-right: 24px;

    @media (max-width: 992px) {
      margin-right: 20px;
      max-width: 466px;
    }

    @media (max-width: 600px) {
      max-width: 560px;
      margin-right: 0;
    }

    &_content {
      .card_wrapper:last-child {
        @media (max-width: 600px) {
          margin-bottom: 0px;
        }
      }
    }

    @media (max-width: 600px) {
      font-size: 16px;
      line-height: 20px;
      font-style: normal;
      font-family: $ff-semi;
      font-weight: $fw-600;
      color: $black_1;
      margin-bottom: 20px;
    }

    h4 {
      font-size: 14px;
      line-height: 18px;
      color: $black_1;
      font-style: normal;
      font-family: $ff-semi;
      font-weight: $fw-600;
      margin-bottom: 12px;
    }
  }

  .right_side {
    font-size: 14px;
    line-height: 18px;
    width: 100%;

    @media (max-width: 600px) {
      font-size: 16px;
      line-height: 20px;
      font-style: normal;
      font-family: $ff-semi;
      font-weight: $fw-600;
      color: $black_1;
      margin-bottom: 20px;
    }

    @media (max-width: 992px) {
      max-width: 466px;
    }

    &_content {
      .card_wrapper:last-child {
        @media (max-width: 600px) {
          margin-bottom: 0px;
        }
      }
    }

    h4 {
      font-size: 14px;
      line-height: 18px;
      color: $black_1;
      font-style: normal;
      font-family: $ff-semi;
      font-weight: $fw-600;
      margin-bottom: 12px;
    }
  }
}
