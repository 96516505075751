@import './assets/styles/variable';

@font-face {
  font-family: 'Gilroy-Bold';
  src: url('assets/fonts/Gilroy-Bold.woff'), url('assets/fonts/Gilroy-Bold.woff2') format('truetype');
  font-weight: $fw-700;
}

@font-face {
  font-family: 'Gilroy-Semibold';
  src: url('assets/fonts/Gilroy-Semibold.woff'), url('assets/fonts/Gilroy-Semibold.woff2') format('truetype');
  font-weight: $fw-600;
}

@font-face {
  font-family: 'Gilroy-Regular';
  src: url('assets/fonts/Gilroy-Regular.woff'), url('assets/fonts/Gilroy-Regular.woff2') format('truetype');
  font-weight: $fw-400;
}

.App {
  height: 100%;
  width: 100%;
  overflow: scroll;

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
}

*,
body {
  font-family: $ff-regular;
  font-weight: $fw-400;
  font-size: 14px;
  line-height: 18px;
  color: $black_1;
}
