@import '../../assets/styles/variable';

.pClass {
  display: flex;
  align-items: center;
  text-align: center;

  p {
    width: 114px;
    font-size: 20px;
    line-height: 25px;
    font-style: normal;
    color: $black_1;
    font-family: $ff-semi;
    font-weight: $fw-600;

    @media (max-width: 600px) {
      margin-right: 94px;
    }
  }
}

.total_amount {
  display: flex;
  position: relative;
  align-items: center;
  max-width: 68px;
  width: 100%;
  background-color: $gray_3;
  border: 1px solid #e1e4f5;
  border-radius: 8px;
  justify-content: space-between;
  cursor: pointer;
  margin-right: 52px;

  @media (max-width: 600px) {
    margin-right: 0;
  }

  &::after {
    content: '';
    width: 1px;
    height: 16px;
    position: absolute;
    background-color: $gray_2;
    right: -26px;

    @media (max-width: 600px) {
      display: none;
    }
  }

  .number {
    font-size: 14px;
    line-height: 18px;
    color: $black_1;
    padding: 6px 8px 4px 8px;
  }

  .percent {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 18px;
    background-color: $blue;
    color: #ffffff;
    margin: 2px;
    padding: 6px 8px 4px 8px;
    border-radius: 6px;
  }
}

.userJourney__data {
  margin-bottom: 32px;
}
