$black_1: #1f264d;
$black_2: #8087a8;
$gray_1: #9ba2c7;
$gray_2: #ced3eb;
$gray_3: #e1e4f5;
$blue_3: rgba(230, 233, 245, 0.5);
$blue_dark: #0f2699;
$blue: #002aff;
$blue_hover: #7088ff;
$blue_2: rgba(0, 42, 255, 0.3);
$grey_bg: #f5f6fa;
$gradient_green: linear-gradient(358.92deg, #00ffa0 0.83%, #cfffed 99%);
$gradient_turquoise: linear-gradient(359.5deg, #54d9d9 0.4%, #ccffff 99.53%);
$gradient_blue: linear-gradient(359.71deg, #0015ff 0.27%, #b2b9ff 99.77%);
$red: #ff2567;
$green: #3acc86;
$green_2: #baedd5;
$green_3: #f0faf5;
$turquoise: #54d9d9;
$yellow: #ffc122;
$violet: #7200f4;
$green_dark: #0c9955;

$ff-regular: 'Gilroy-Regular', 'Georgia', serif, system-ui;
$ff-semi: 'Gilroy-Semibold', 'Georgia', serif, system-ui;
$ff-bold: 'Gilroy-Bold', 'Georgia', serif, system-ui;

$fw-400: 400;
$fw-600: 600;
$fw-700: 700;
