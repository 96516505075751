@import 'src/assets/styles/variable';

.custom-range-picker {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 220px;
  width: 100%;
  border: 1px solid #e1e4f5;
  padding: 8px 16px 8px 8px;
  border-radius: 8px;
  margin-right: 24px;

  //&::after {
  //  position: absolute;
  //  content: '';
  //  width: 10px;
  //  height: 10px;
  //  background-image: url('../../../assets/static/svg/chevron-right.svg');
  //  background-repeat: no-repeat;
  //  right: 8px;
  //  top: 48%;
  //  transform: translateY(-48%);
  //  z-index: 100;
  //}
  //&::before {
  //  position: absolute;
  //  content: '';
  //  width: 10px;
  //  height: 10px;
  //  background-image: url('../../../assets/static/svg/chevron-left.svg');
  //  background-repeat: no-repeat;
  //  left: 8px;
  //  top: 48%;
  //  z-index: 100;
  //  transform: translateY(-48%);
  //}

  .arrow_left,
  .arrow_right {
    position: absolute;
    cursor: pointer;
    outline: none;
    border: none;
    background-color: #fff;

    &:disabled {
      cursor: default;
    }
  }

  .arrow_left {
    left: 8px;
  }
  .arrow_right {
    right: 8px;
  }

  .react-datepicker-popper {
    max-width: 250px;
    width: 100%;
  }

  .react-datepicker__navigation--previous {
    top: 18px;
    left: 17px;
  }

  .react-datepicker__navigation--next {
    top: 18px;
    right: 17px;
  }

  .react-datepicker__current-month {
    color: #1f264d;
    font-size: 16px;
    font-weight: $fw-600;
    font-family: $ff-semi;
    margin-bottom: 14px;
  }

  .react-datepicker__day-name {
    color: #1f264d;
    font-size: 12px;
    font-weight: $fw-600;
    font-family: $ff-semi;
  }

  .react-datepicker__day {
    font-size: 12px;
    font-family: $ff-regular;
    font-weight: $fw-400;
  }

  .react-datepicker__day--keyboard-selected {
    background: rgba(0, 42, 255, 0.3);
  }

  .react-datepicker__day--in-range {
    background: #002aff;
  }

  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
  .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
  .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
  .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
    background: rgba(0, 42, 255, 0.3) !important;
  }

  .react-datepicker__navigation-icon::before {
    width: 5px;
    height: 6px;
    border-width: 2px 2px 0 0;
  }

  .react-datepicker {
    width: 100%;
    padding: 18px 0;
    display: flex;
    justify-content: center;
    border: none;
    box-shadow: 0 5px 15px rgba(56, 57, 66, 0.2);
    border-radius: 12px;
  }

  .react-datepicker__month-container {
    background: #fff;
  }

  .react-datepicker__header {
    background: #fff;
    border-bottom: none;
  }

  input {
    text-align: center;
    font-size: 12px;
    line-height: 15px;
    font-family: $ff-bold;
    font-weight: $fw-700;
    margin: 0 4px;
    border: none;
    width: 100%;
    outline: none;
    cursor: pointer;

    &:disabled {
      cursor: default;
      background-color: #fff;
    }
  }
}
