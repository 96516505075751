@import 'src/assets/styles/variable';

.compliance_wrapper {
  width: 100%;
  display: flex;
  padding: 20px 24px;
  border: 1px solid $gray_3;
  border-radius: 12px;
  flex-direction: column;
  margin-bottom: 24px;

  .compliance_title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    img {
      margin-right: 5px;
    }

    .title {
      font-family: $ff-semi;
      font-weight: $fw-600;
      font-size: 20px;
      color: $black_1;
    }
  }

  .compliance_log_rate {
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;

    .log_rate {
      max-width: 300px;
      width: 100%;
      display: flex;
      align-items: center;

      p {
        font-style: normal;
        font-family: $ff-regular;
        font-weight: $fw-400;
        font-size: 14px;
        line-height: 17px;
        color: $black_1;
        margin-right: 8px;
      }

      .log_rate_info {
        display: flex;
        align-items: center;
        max-width: 100%;
        width: max-content;
        background-color: $blue_3;
        font-family: $ff-semi;
        font-weight: $fw-600;
        font-size: 14px;
        line-height: 17px;
        color: $black_1;
        border-radius: 4px;
        padding: 4px 8px;
        text-align: center;
      }
    }

    .gender_marker {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $black_1;
      margin: 13px 0 0 0;

      .blue {
        margin-right: 24px;
      }

      .blue,
      .red {
        display: flex;
        align-items: baseline;

        p {
          font-style: normal;
          font-family: $ff-regular;
          font-weight: $fw-400;
          font-size: 14px;
          color: $black_1;
        }

        img {
          width: 10px;
          height: 10px;
          margin-right: 5px;
        }
      }
    }
  }
}
