@import '../../../src/assets/styles/variable';

.login__wrapper {
  max-width: 1440px;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  @media (max-width: 600px) {
    padding: 0 20px;
  }

  .login__header {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      margin-top: 24px;

      @media (max-width: 600px) {
        margin-top: 28px;
        width: 68px;
        height: 48px;
      }
    }
  }

  .login__content {
    display: flex;
    justify-content: center;
    margin-top: 217px;

    @media (max-width: 600px) {
      margin-top: 72px;
    }
  }
}
