@import '../../../../assets/styles/variable';

.header__wrapper {
  width: 100%;
  padding: 20px 0;
  display: flex;
  justify-content: space-between;

  @media (max-width: 600px) {
    display: flex;
    align-items: center;
  }

  @media (max-width: 992px) {
    width: inherit;
    padding: 15px 20px;
    margin: 0;
  }

  .header__left {
    display: flex;
    align-items: center;

    &_logo {
      width: 66px;
      height: 66px;
      margin-right: 16px;

      @media (max-width: 992px) {
        width: 52px;
        height: 52px;
        margin-right: 12px;
      }
    }

    &__logoWrapper {
      padding-bottom: 7px;
    }

    &__firstBlock {
      h2 {
        font-size: 28px;
        font-family: $ff-bold;
        font-weight: $fw-700;
        line-height: 36px;
        color: $black_1;

        @media (max-width: 992px) {
          font-size: 22px;
          line-height: 28px;
        }
      }
    }

    &__secondBlock {
      display: flex;
      align-items: center;

      p {
        font-size: 9px;
        margin-right: 4px;
      }

      .secondBlock__symbol {
        width: 8px;
        height: 8px;
      }

      .secondBlock__SO {
        width: 77px;
        height: 8px;
      }
    }
  }

  .form__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background-color: $gray_3;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;

    @media (max-width: 600px) {
      width: 24px;
      height: 24px;
    }

    &:hover {
      background-color: rgba(230, 233, 245, 0.5);
      & img {
        filter: opacity(70%);
      }
    }

    &:active {
      background-color: #ced3eb;
      & img {
        filter: opacity(100%);
      }
    }

    img {
      width: 18px;
      height: 18px;
      margin-left: 2px;

      @media (max-width: 600px) {
        width: 18px;
        height: 18px;
        opacity: 1;
      }
    }
  }
}
