@import '/src/assets/styles/variable';

.quickStats__wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  h3 {
    margin-bottom: 12px;
    font-family: $ff-semi;
    font-weight: $fw-600;
    font-size: 20px;
    line-height: 25px;
    color: $black_1;
  }

  .quickStats__items {
    display: flex;
    width: 100%;

    @media (max-width: 600px) {
      display: grid;
      grid-template: 1fr/ 1fr 1fr;
      column-gap: 30px;
      row-gap: 16px;
    }

    .quickStats__item {
      display: flex;
      max-width: 200px;
      width: 100%;
      margin-right: 24px;

      @media (max-width: 600px) {
        max-width: 158px;
        width: 100%;
      }

      .content {
        display: flex;
        flex-direction: column;

        .grey_line_wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 17px;
          height: 40px;

          .grey_line {
            width: 12px;
            height: 4px;
            background-color: $gray_2;
          }
        }

        .content__firstBlock {
          display: flex;
          align-items: center;

          .quickStats__Circular {
            width: 25px;
            height: 25px;
            margin-bottom: 5px;
            margin-right: 10px;
          }

          .content__top {
            display: flex;
            font-family: $ff-semi;
            font-weight: $fw-600;
            font-size: 32px;
            line-height: 40px;
            color: $black_1;

            @media (max-width: 600px) {
              font-size: 28px;
              line-height: 35px;
            }
          }
        }

        .content__down {
          font-style: normal;
          font-family: $ff-regular;
          font-weight: $fw-400;
          font-size: 14px;
          line-height: 17px;
          color: $black_1;
        }
      }
    }
  }
}
