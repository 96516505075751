@import 'src/assets/styles/variable';

.content_types_wrapper {
  width: 98%;
  h4 {
    font-family: $ff-semi;
    font-weight: $fw-600;
    font-size: 14px;
    line-height: 17px;
    color: $black_1;
    margin-bottom: 8px;
  }
}
