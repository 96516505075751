@import 'src/assets/styles/variable';

.card_wrapper {
  max-width: 560px;
  width: 100%;
  display: flex;
  margin-bottom: 16px;

  @media (max-width: 992px) {
    margin-bottom: 12px;
  }

  .card_image {
    max-width: 88px;
    width: 100%;
    height: 88px;
    background-color: $blue_3;
    border-radius: 12px;
    display: flex;
    position: relative;
    margin-right: 15px;

    .image_top {
      position: absolute;
      top: 10%;
      left: 10%;
      width: 46px;
      height: 21px;
      border-radius: 12px;
      background-color: $gray_3;
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        font-style: normal;
        font-family: $ff-semi;
        font-weight: $fw-600;
        color: $black_1;
        font-size: 12px;
        padding-top: 2px;
      }
    }
  }
  .card_context {
    h5 {
      width: 100%;
      font-style: normal;
      font-family: $ff-semi;
      font-weight: $fw-600;
      font-size: 16px;
      line-height: 20px;
      color: $black_1;
      margin-bottom: 4px;
    }
    p {
      font-style: normal;
      font-family: $ff-regular;
      font-weight: $fw-400;
      font-size: 14px;
      line-height: 17px;
      color: $black_1;
    }
  }
}
