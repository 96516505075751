@import 'src/assets/styles/variable';

.navbar__wrapper {
  padding-top: 16px;
  max-width: 336px;
  width: 100%;
  background-color: $gray_3;
  border-top-left-radius: 12px;
  display: flex;
  flex-direction: column;

  @media (max-width: 992px) {
    padding-top: 0;
    max-width: 992px;
    border-top-left-radius: inherit;
    flex-direction: row;
    overflow-x: scroll;
    white-space: nowrap;
    height: 50px;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .link {
    font-family: $ff-semi;
    font-weight: $fw-600;
    border: none;
    display: flex;
    align-items: center;
    padding: 18px 24px;
    color: $black_1;
    transition: all 0.5ms ease-in-out;
    font-size: 18px;
    line-height: 23px;
    background-color: $gray_3;

    @media (max-width: 992px) {
      display: inline-block;
      padding: 12px 20px 15px 20px;
      border-bottom: 3px solid transparent;
    }

    &:hover {
      cursor: pointer;
      background-color: rgba(15, 38, 153, 0.04);
    }
  }

  .activeLink {
    background-color: rgba(15, 38, 153, 0.06);
    border-left: 3px solid $blue;

    &:hover {
      cursor: default;
    }

    @media (max-width: 992px) {
      border-bottom: 5px solid $blue-dark;
      border-left: none;
      font-family: $ff-semi;
      font-weight: $fw-600;
    }

    @media (max-width: 600px) {
      border-left: none;
    }
  }
}
