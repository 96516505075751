@import '../../../../assets/styles/variable';

.lineBar__wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  @media (max-width: 992px) {
    &:last-child {
      margin-bottom: 0;
    }

    margin-bottom: 4px;
    justify-content: space-between;
  }

  .lineBar__wrapper_amount {
    max-width: 30px;
    width: 100%;
    font-size: 14px;
    line-height: 18px;
    font-style: normal;
    color: $black-1;
    font-family: $ff-semi;
    font-weight: $fw-600;
    text-align: center;

    @media (max-width: 992px) {
      text-align: right;
    }
  }
  .lineBar_text {
    max-width: 75px;
    width: 100%;
    font-style: normal;
    font-family: $ff-regular;
    font-weight: $fw-400;
    font-size: 14px;
    line-height: 18px;
    color: $black_1;
    @media (max-width: 992px) {
      width: 130px;
    }
  }
  .line {
    border-radius: 5px;
    width: 100%;
    height: 10px;
    margin: 0 12px;
  }
}
