@import 'src/assets/styles/variable';

.compliance_wrapper {
  max-width: 1032px;
  width: 100%;
  padding: 24px 24px 32px 24px;
  background-color: #ffffff;
  border-top-right-radius: 12px;

  .hClassName {
    font-style: normal;
    font-family: $ff-bold;
    font-weight: $fw-700;
    font-size: 28px;
    line-height: 35px;
    color: $black_1;
  }
}

.compliance {
  background-color: #fff;
  border-top-right-radius: 12px;

  .component_layout {
    display: flex;
    flex-direction: column;

    .face_wrapper_content {
      @media (max-width: 1410px) {
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 10px;
        margin: 0 auto;
      }

      .circle_chart {
        @media (max-width: 1410px) {
          margin-bottom: 15px;
        }
      }

      .face_wrapper_content_totals {
        @media (max-width: 1410px) {
          padding: 12px 16px 0 0;
        }

        .face_wrapper_content_title {
          @media (max-width: 1410px) {
            margin-bottom: 8px;
          }
        }
      }
    }

    .compliance__itemWrapper {
      margin-bottom: 32px;

      .compliance_wrapper {
        @media (max-width: 600px) {
          margin-bottom: 12px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .demographics_difference {
      .compliance_title {
        @media (max-width: 600px) {
          flex-direction: column;
          align-items: flex-start;
        }

        .title {
          @media (max-width: 600px) {
            font-size: 16px;
          }
        }

        .gender_marker {
          @media (max-width: 600px) {
            margin: 10px 0;
          }
        }
      }
    }
  }

  .demographics_difference {
    .compliance_wrapper {
      height: 100%;
      margin-bottom: 0;
    }

    .compliance_wrapper {
      .compliance_title {
        margin-bottom: 15px;

        .gender_marker {
          display: flex;
          gap: 12px;
          font-size: 14px;
          font-family: $ff-regular;
          font-weight: $fw-400;
          margin: 0 0 0 auto;

          .red,
          .blue {
            display: flex;
            align-items: baseline;
            font-size: 14px;
          }
        }
      }
    }
  }

  @media (max-width: 600px) {
    .compliance_log_rate {
      flex-wrap: wrap;
    }

    .face_wrapper {
      .face_wrapper_title {
        padding-bottom: 0;
        margin-bottom: 4px;
      }

      .face_wrapper_content {
        flex-wrap: wrap;
        gap: 12px;

        .circle_chart {
          margin-bottom: 0;
          max-width: 100%;
        }

        .face_wrapper_content_totals {
          width: 100%;
          max-width: 100%;
          gap: 8px;
        }
      }
    }
  }
}
