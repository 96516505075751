@import 'src/assets/styles/variable';

.form__btn {
  max-width: 400px;
  width: 100%;
  height: 56px;
  background-color: $blue;
  font-family: $ff-bold;
  font-weight: $fw-700;
  color: #ffffff;
  border-radius: 8px;
  margin: 16px 0;
  text-transform: uppercase;
  transition: all 0.5s ease-in-out;
  border: none;

  &:disabled {
    background-color: $blue_2;
  }

  &:hover {
    background-color: $blue_hover;
  }

  &:active {
    background-color: $blue_dark;
  }
}

.white {
  display: flex;
  align-items: center;
  align-self: center;
  height: 25px;
  color: $black_1;
  background-color: #ffffff;
  border: none;
  font-family: $ff-bold;
  font-weight: $fw-700;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  border-bottom: 2px solid transparent;
  transition: opacity linear 0.2s;
  cursor: pointer;
  letter-spacing: 0.5px;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    opacity: 0.6;
  }
}
.active {
  border-bottom: 2px solid #1f264d;

  &:hover {
    opacity: 1;
  }
}

.active_filters_btn {
  max-width: 85px;
  background-color: $blue;
  color: #ffffff;
  border-radius: 16px;
  cursor: pointer;
  padding: 4px 12px;
  outline: none;
  font-family: $ff-regular;
  font-weight: $fw-400;
  font-size: 16px;
  line-height: 22px;
  border: none;
  transition: all 0.2ms ease-in-out;
}

.filters_btn {
  @extend .active_filters_btn;
  background-color: #e1e4f5;
  color: #1f264d;

  &:hover {
    color: grey;
  }
}

@media (max-width: 600px) {
  .filters_btn {
    padding: 4px 8px;
  }

  .active_filters_btn {
    padding: 4px 8px;
  }
}
