@import '../../../assets/styles/variable';

.noLog {
  max-width: 182px;
  width: 100%;
  background-color: $blue_3;
  border-radius: 4px;
  padding: 4px 8px;
  font-style: normal;
  font-weight: $fw-600;
  font-size: 14px;
  line-height: 17px;
  color: $black_2;
}
