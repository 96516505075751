@import 'src/assets/styles/variable';

.header_wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    margin-top: 32px;
    flex-direction: column;
    margin-bottom: 16px;
  }

  img {
    cursor: pointer;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 0.8;
    }
  }

  &_left_side {
    max-width: 724px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    @media (max-width: 600px) {
      width: 339px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
    }

    .title {
      display: flex;
      font-style: normal;

      p {
        font-size: 20px;
        line-height: 25px;
        color: $black_1;
        font-family: $ff-semi;
        font-weight: $fw-600;
      }

      .interaction {
        position: relative;
        display: flex;
        justify-content: center;
        width: 24px;
        height: 24px;
        margin-left: 4px;

        @media (max-width: 600px) {
          align-items: center;
        }

        img {
          margin-top: 4px;
          width: 16px;
          height: 16px;

          @media (max-width: 600px) {
            margin-top: 4px;
          }
        }

        .message {
          position: absolute;
          top: -40px;
          left: -76px;
          width: 186px;
          padding: 8px 10px;
          background-color: $gray_3;
          align-items: center;
          border-radius: 8px;
          font-style: normal;
          font-family: $ff-semi;
          font-weight: $fw-600;
          font-size: 14px;
          line-height: 17px;
          color: $black_1;

          @media (max-width: 600px) {
            max-width: 186px;
            width: 100%;
            background-color: $gray_3;
            padding: 8px 12px;
          }
        }
      }
    }
  }

  &_right_side {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media (max-width: 600px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .input__wrapper {
      margin-right: 24px;
    }

    .btn_state {
      display: flex;
      gap: 20px;

      .white:first-child {
        width: 119px;
      }

      .white:last-child {
        width: 108px;
      }
    }
  }
}
