@import 'src/assets/styles/variable';

.lineBarItem__wrapper {
  max-width: 984px;
  width: 100%;
  height: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;

  @media (max-width: 992px) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media (max-width: 600px) {
    height: 36px;
  }

  .mobile_left {
    max-width: 312px;
    width: 100%;

    @media (max-width: 600px) {
      max-width: 287px;
      width: 100%;
    }

    .title {
      font-style: normal;
      font-weight: $fw-400;
      font-family: $ff-regular;
      font-size: 14px;
      line-height: 17px;
      color: $black_1;

      @media (max-width: 992px) {
        text-align: left;
        margin-bottom: 4px;
      }
    }
  }

  .mobile_right {
    max-width: 648px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 992px) {
      display: flex;
    }

    .lineBar {
      max-width: 584px;
      border-radius: 24px;
      width: 100%;
      height: 10px;
      margin: 0 24px;

      @media (max-width: 992px) {
        margin: 0;
      }
    }

    p {
      max-width: 40px;
      width: 100%;
      font-style: normal;
      font-family: $ff-semi;
      font-weight: $fw-600;
      font-size: 14px;
      line-height: 17px;
      color: $black_1;
      text-align: right;
    }
  }
}
