@import 'src/assets/styles/variable';

.form__wrapper {
  max-width: 400px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 600px) {
    max-width: 335px;
    width: 100%;
  }

  h1 {
    font-family: $ff-bold;
    font-weight: $fw-700;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    color: $black_1;

    @media (max-width: 600px) {
      font-size: 28px;
      line-height: 35px;
    }
  }

  .form__inputArea {
    max-width: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 32px;

    input {
      height: 56px;
      border-radius: 8px;
      border: 1px solid $gray_2;
      margin-bottom: 8px;
      outline: none;
      padding: 10px;

      &::placeholder {
        font-family: $ff-regular;
        font-weight: $fw-400;
        font-size: 16px;
        line-height: 22px;
        color: $gray_2;
        margin: 0 4px;
      }
    }
  }

  a {
    color: $black_2;
  }
}
