@import 'src/assets/styles/variable';

.component_layout {
  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 600px) {
      flex-direction: column;
    }

    h2 {
      font-style: normal;
      font-family: $ff-bold;
      font-weight: $fw-700;
      font-size: 28px;
      line-height: 35px;
      color: $black_1;

      @media (max-width: 600px) {
        font-size: 26px;
        line-height: 33px;
        margin-right: 0;
      }
    }
  }

  &_notification {
    margin-bottom: 32px;

    h3 {
      font-style: normal;
      font-weight: $fw-600;
      font-family: $ff-semi;
      font-size: 20px;
      line-height: 25px;
      color: $black_1;
      margin-bottom: 12px;

      @media (max-width: 600px) {
        margin-top: 0;
      }
    }

    .notification__wrapper {
      display: flex;

      @media (max-width: 600px) {
        flex-direction: column;
      }
    }

    .notification-data {
      margin-right: 24px;
      max-width: 332px;
      width: 100%;

      @media (max-width: 600px) {
        max-width: 332px;
        margin-bottom: 20px;
        margin-right: 24px;
      }

      h4 {
        width: 100%;
        font-style: normal;
        font-weight: $fw-600;
        font-size: 14px;
        line-height: 18px;
        color: $black_1;
        margin-bottom: 12px;

        @media (max-width: 600px) {
          max-width: 560px;
        }
      }

      &__item {
        width: 100%;
        list-style: none;
        font-style: normal;
        font-family: $ff-regular;
        font-weight: $fw-400;
        font-size: 14px;
        line-height: 18px;
        color: $black_1;
        margin-bottom: 12px;

        @media (max-width: 600px) {
          max-width: 560px;
        }
      }

      & :last-child {
        margin-bottom: 0;
      }
    }

    .notification-chart {
      max-width: 312px;
      width: 100%;

      @media (max-width: 600px) {
        max-width: 600px;
        width: 100%;

        & :last-child {
          margin-bottom: 0;
        }
      }

      &__ratio {
        max-width: 312px;
        width: 100%;
        font-style: normal;
        font-weight: $fw-600;
        font-size: 14px;
        line-height: 18px;
        color: $black_1;
        margin-bottom: 12px;

        @media (max-width: 600px) {
          max-width: 600px;
        }
      }

      .lineBar__wrapper {
        .rc-progress-line {
          max-width: 178px;
          width: 100%;
          margin-right: 8px;
          border-radius: 5px;

          @media (max-width: 600px) {
            max-width: 415px;
          }
        }

        .lineBar_text {
          max-width: 75px;
          width: 100%;
          font-style: normal;
          font-family: $ff-regular;
          font-weight: $fw-400;
          font-size: 14px;
          line-height: 18px;
          color: $black_1;
          @media (max-width: 992px) {
            width: 130px;
          }
        }

        .lineBar_counter {
          width: 24px;
          text-align: right;
          font-size: 14px;
          line-height: 18px;
          font-style: normal;
          font-weight: 600;
          font-family: $ff-semi;
          color: $black_1;
          margin-top: 3px;
        }
      }
    }
  }

  &_prompt {
    margin-bottom: 32px;

    h3 {
      font-style: normal;
      font-weight: 600;
      font-family: $ff-semi;
      font-size: 20px;
      line-height: 25px;
      color: $black_1;
      margin-bottom: 12px;

      @media (max-width: 600px) {
        margin-top: 0;
      }
    }

    .prompt__wrapper {
      display: flex;

      @media (max-width: 600px) {
        flex-direction: column;
      }
    }

    .prompt-data {
      max-width: 332px;
      width: 100%;
      margin-right: 24px;

      @media (max-width: 600px) {
        margin-right: 0;
        margin-bottom: 20px;
      }

      h4 {
        width: 100%;
        font-style: normal;
        font-weight: 600;
        font-family: $ff-semi;
        font-size: 14px;
        line-height: 18px;
        color: $black_1;
        margin-bottom: 12px;

        @media (max-width: 600px) {
          width: 100%;
        }
      }

      &__item {
        max-width: 312px;
        width: 100%;
        list-style: none;
        font-style: normal;
        font-family: $ff-regular;
        font-weight: $fw-400;
        font-size: 14px;
        line-height: 18px;
        color: $black_1;
        margin-bottom: 12px;

        @media (max-width: 600px) {
          width: 100%;
        }
      }

      & :last-child {
        margin-bottom: 0;
      }
    }

    .prompt-chart {
      max-width: 312px;
      width: 100%;

      @media (max-width: 600px) {
        max-width: 560px;
      }

      &__ratio {
        max-width: 312px;
        width: 100%;
        font-style: normal;
        font-weight: 600;
        font-family: $ff-semi;
        font-size: 14px;
        line-height: 18px;
        color: $black_1;
        margin-bottom: 12px;

        @media (max-width: 600px) {
          max-width: 560px;
        }
      }

      & .lineBar__wrapper {
        @media (max-width: 600px) {
          margin-bottom: 8px;
        }

        .rc-progress-line {
          max-width: 178px;
          width: 100%;
          margin-right: 8px;
          border-radius: 5px;

          @media (max-width: 600px) {
            max-width: 415px;
          }
        }

        .lineBar_text {
          max-width: 75px;
          width: 100%;
          font-style: normal;
          font-family: $ff-regular;
          font-weight: $fw-400;
          font-size: 14px;
          line-height: 18px;
          color: $black_1;
        }

        .lineBar_counter {
          width: 24px;
          text-align: right;
          font-size: 14px;
          line-height: 18px;
          font-style: normal;
          font-weight: 600;
          font-family: $ff-semi;
          color: $black_1;
          margin-top: 3px;
        }
      }
    }
  }

  &_email {
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;

    h3 {
      font-style: normal;
      font-weight: 600;
      font-family: $ff-semi;
      font-size: 20px;
      line-height: 25px;
      color: $black_1;
      margin: 0 0 12px 0;
    }

    @media (max-width: 600px) {
      padding-bottom: 0;
    }

    .email-wrapper {
      display: flex;

      @media (max-width: 600px) {
        flex-wrap: wrap;
      }

      & :last-child {
        @media (max-width: 600px) {
          margin-bottom: 0;
        }
      }

      & :nth-child(even) {
        @media (max-width: 600px) {
          margin-right: 0;
        }
      }

      &__empty {
        display: flex;

        p {
          font-size: 32px;
          line-height: 40px;
          font-style: normal;
          font-weight: 600;
          font-family: $ff-semi;
          color: $gray_2;
        }
      }

      &__item {
        width: 200px;
        display: flex;
        flex-direction: column;
        margin-right: 24px;

        @media (max-width: 600px) {
          width: 155px;
          margin-bottom: 16px;
        }
      }

      &__item-chart {
        &_wrapper {
          display: flex;
          align-items: center;
        }

        svg {
          width: 26px;
          height: 26px;
          margin-right: 8px;
          margin-bottom: 5px;
        }

        &_title {
          font-size: 32px;
          line-height: 40px;
          font-style: normal;
          font-weight: 600;
          font-family: $ff-semi;
          color: $black_1;
        }
      }

      &__item-description {
        max-width: 200px;
        font-size: 14px;
        line-height: 18px;
        font-style: normal;
        font-family: $ff-regular;
        font-weight: $fw-400;
        color: $black_1;
      }
    }
  }
}
