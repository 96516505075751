@import 'src/assets/styles/variable.scss';

.component_layout {
  padding: 24.5px 24px 24px;
  background-color: #fff;
  width: 100%;
  .full_program {
    font-weight: bold;
    line-height: 0;
  }
  @media (max-width: 992px) {
    max-width: 992px;
    margin: 0;
    padding: 20px;
  }

  .component_layout_header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    @media (max-width: 600px) {
      margin-bottom: 0;
    }
  }

  .header_title {
    max-width: 386px;
    width: 100%;

    @media (max-width: 600px) {
      max-width: 600px;
      margin-bottom: 14px;
    }
  }

  .header_filters {
    display: flex;
    width: 100%;
    align-content: center;
    justify-content: flex-end;

    .custom-range-picker {
      width: 220px;
    }

    @media (max-width: 600px) {
      flex-direction: column-reverse;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      margin-bottom: 16px;
    }

    .button_wrapper_daly {
      display: flex;
      align-items: center;
      gap: 20px;

      .white:last-child {
        width: 111px;
      }
    }
    @media (max-width: 600px) {
      .custom-range-picker {
        margin-right: 0;
        max-width: 100%;
        padding: 11px;
        width: 100%;
      }

      .button_wrapper_daly {
        flex-direction: row-reverse;
      }

      #full_program {
        margin-bottom: 14px;
      }

      .white {
        align-self: flex-start;
      }
    }
  }
}
