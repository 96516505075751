@import '../../assets/styles/variable';

.appUsage__wrapper {
  max-width: 1032px;
  width: 100%;
  padding: 27px 24px 32px 24px;
  background-color: #ffffff;

  @media (max-width: 600px) {
    width: 100%;
    padding: 12px 20px 0 20px;
  }
}

.appUsage__header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 22px 24px;
  box-shadow: 0 1px 25px rgba(31, 41, 76, 0.06);
  border-radius: 0 12px 0 0;
  position: sticky;
  top: 0;
  z-index: 22;

  @media (max-width: 600px) {
    box-shadow: none;
    padding: 0 20px;
    position: static;
  }

  p {
    font-size: 28px;
    line-height: 36px;
    font-family: $ff-bold;
    font-weight: $fw-700;
    color: $black_1;

    @media (max-width: 600px) {
      font-size: 26px;
      line-height: 33px;
    }
  }

  .modal__div {
    position: relative;
    border-radius: 8px;

    .header__filter__btn {
      background-color: #ffffff;
      height: 42px;
      color: $black_1;
      display: flex;
      align-items: center;
      transition: all 0.3ms ease-in-out;
      border-radius: 8px;
      border: 1px solid #bfc1c7;
      padding: 9px 12px;

      @media (max-width: 600px) {
        padding: 8px;
        height: 32px;
        border: 1px solid $gray_3;
      }

      img {
        margin-right: 6px;
      }

      p {
        font-family: $ff-bold;
        font-weight: $fw-700;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 1px;
      }

      &:hover {
        border: 1px solid #ffffff;
        background-color: $blue_3;
      }

      &:active {
        background-color: $gray_2;
      }
    }
  }
}
