@import 'src/assets/styles/variable';

.modal__wrapper {
  width: 211px;
  background-color: #ffffff;
  position: absolute;
  top: 108%;
  right: 0;
  border-radius: 12px;
  padding: 20px;
  z-index: 10;
  box-shadow: 0 5px 15px rgba(56, 57, 66, 0.2);
  max-height: 590px;
  overflow-y: scroll;

  @media (max-width: 600px) {
    margin-top: 8px;
    width: 339px;
    max-height: 390px;
    overflow-y: scroll;
  }

  .modal__form {
    h3 {
      margin-bottom: 16px;
      font-style: normal;
      font-family: $ff-semi;
      font-weight: $fw-600;
      font-size: 16px;
      line-height: 20px;
      color: $black_1;
    }

    &_type {
      .input__radio {
        font-family: $ff-semi;
        font-weight: $fw-600;
        margin-bottom: 15px;
        display: flex;
        align-items: center;

        &:hover {
          .label {
            &::before {
              border: 2px solid $blue_hover;
            }
          }
        }

        .radio-btn {
          position: absolute;
          opacity: 0;
          visibility: hidden;

          &:checked + .label {
            &::before {
              width: 8px;
              height: 8px;
              border: 8px double white;
              background-color: $blue;
              transform: translateX(-3px);
            }
            &:hover {
              &::before {
                background-color: #7088ff;
              }
            }
          }

          &:hover {
            &:checked + .label {
              &::before {
                border: 8px double white;
                transform: translateX(-3px);
              }
            }
          }
        }

        .label {
          position: relative;
          font-style: normal;
          font-family: $ff-semi;
          font-weight: $fw-600;
          font-size: 14px;
          line-height: 18px;
          color: $black_1;
          display: flex;
          align-items: center;
          cursor: pointer;
          padding-left: 26px;
        }

        .label::before {
          position: absolute;
          display: inline-block;
          content: '';
          height: 12px;
          width: 12px;
          left: 0;
          border: 2px solid $gray_2;
          border-radius: 50%;
          transition: all 0.25s linear;
        }
      }
    }

    &_gender {
      .container {
        display: block;
        position: relative;
        padding-left: 26px;
        margin-bottom: 15px;
        cursor: pointer;
        font-style: normal;
        font-family: $ff-semi;
        font-weight: $fw-600;
        font-size: 14px;
        line-height: 17px;
        color: $black_1;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }

      & :last-child {
        margin-bottom: 24px;
      }
    }

    button {
      letter-spacing: 1px;
      max-width: 171px;
      width: 100%;
      height: 34px;
      margin: 0;

      @media (max-width: 600px) {
        max-width: 295px;
        width: 100%;
        height: 48px;
        margin-top: 15px;
      }
    }
  }

  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    border: 2px solid $gray_2;
    border-radius: 4px;
  }

  .container:hover input ~ .checkmark {
    border: 2px solid $blue_hover;
    background-color: #ffffff;
    border-radius: 4px;
  }

  .container input:checked ~ .checkmark {
    border: 2px solid $blue;
    background-color: $blue;
    border-radius: 4px;
  }

  .container:hover input:checked ~ .checkmark {
    background-color: #7088ff;
    border-color: #7088ff;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  .container input:checked ~ .checkmark:after {
    display: block;
  }

  .container .checkmark:after {
    left: 2px;
    top: 0;
    width: 5px;
    height: 7px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
