@import 'src/assets/styles/variable';

.filters__wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;

  @media (max-width: 600px) {
    max-width: 375px;
    width: 100%;
  }

  h3 {
    font-style: normal;
    font-family: $ff-bold;
    font-weight: $fw-700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    text-transform: uppercase;
    color: $black_1;
    margin-right: 20px;

    @media (max-width: 600px) {
      margin-right: 0;
      font-size: 12px;
      line-height: 14px;
    }
  }
}
