@import 'src/assets/styles/variable';

.content {
  background-color: #ffffff;
  border-top-right-radius: 12px;
  max-width: 1032px;
  width: 100%;

  @media (max-width: 992px) {
    max-width: 992px;
    border-top-right-radius: 0;
  }

  &_types {
    display: flex;
    flex-direction: column;
    max-width: 890px;
    width: 100%;

    @media (max-width: 992px) {
      display: flex;
      flex-direction: column;

      .noLog {
        display: inline-block;
        margin-left: 0;
      }
    }

    h3 {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 600;
      font-family: $ff-semi;
      font-size: 20px;
      line-height: 25px;
      color: $black_1;
      margin-bottom: 16px;
    }

    &_items {
      display: grid;
      grid-template: 1fr/ 1fr 1fr;
      row-gap: 20px;
      column-gap: 24px;
      width: 100%;

      @media (max-width: 992px) {
        grid-template: 1fr/ 1fr;
        width: 100%;
        max-width: 950px;
      }
    }
  }

  &_items {
    display: flex;
    flex-direction: column;
    max-width: 890px;

    h3 {
      font-style: normal;
      font-weight: 600;
      font-family: $ff-semi;
      font-size: 20px;
      line-height: 25px;
      color: $black_1;
      margin: 44px 0 16px 0;

      @media (max-width: 992px) {
        margin: 32px 0 16px 0;
      }
    }
  }
}
