@import '../../../assets/styles/variable';

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.wrapper {
  background-color: $grey_bg;
  height: 100vh;

  @media (max-width: 992px) {
    overflow: hidden;
  }

  .layout__wrapper {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding: 0 36px;

    @media (max-width: 992px) {
      padding: 0;
    }

    .content__wrapper {
      display: flex;
      height: 90vh;

      @media (max-width: 992px) {
        flex-direction: column;
        margin-top: 0;
        margin-left: 0;
      }

      .general_content {
        overflow-y: scroll;
        max-width: 1033px;
        width: 100%;
        position: relative;
        background-color: #ffffff;
        border-top-right-radius: 12px;
        &::-webkit-scrollbar {
          display: none;
        }

        @media (max-width: 600px) {
          padding-top: 20px;
          border-top-right-radius: 0;
        }

        @media (max-width: 992px) {
          display: flex;
          flex-direction: column;
          height: 80vh;
          overflow-x: hidden;
        }
      }
    }
  }
}
