html,
body {
  margin: 0;
  height: 100vh;
  width: 100%;
}

#root {
  overflow: hidden;
  height: 100vh;
  width: 100%;
}
